import React, { useState, useEffect, useRef } from 'react';

import chat1 from 'assets/img/chat-img/chat-1.png';
import chat2 from 'assets/img/chat-img/chat-2.png';
import chat3 from 'assets/img/chat-img/chat-3.png';
import chat4 from 'assets/img/chat-img/chat-4.png';
import chat5 from 'assets/img/chat-img/chat-5.png';
import chat5top from 'assets/img/chat-img/chat-5-top.png';
import vector6 from "assets/img/chat-img/Vector-6.png";
import vector5 from "assets/img/chat-img/Vector-5.png";
import brandbg from "assets/img/chat-img/brand-bg.png";
import wix from "assets/img/chat-img/wix.png";
import shopify from 'assets/img/chat-img/shopify.png';
import thumb_up from "assets/img/icon/thumb_up.png";
import tree_png from "assets/img/icon/account_tree.png";
import querystats from "assets/img/icon/query_stats.png";
import chat from "assets/img/icon/chat.png";
import ellipse from 'assets/img/chat-img/Ellipse.png';



const Section1 = () => {

    const [loaded, setLoaded] = useState(false);
    const sectionRef = useRef(null);
    const imagesRef = useRef([]);

    useEffect(() => {

        if ('IntersectionObserver' in window) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            };

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {

                        imagesRef.current.forEach((imgRef, index) => {
                            if (index == 0) {
                                setTimeout(() => {
                                    imgRef.classList.add('slide-in-img-right');
                                }, index * 300);
                            } else if (index > 0 && index <= 4) {
                                setTimeout(() => {
                                    imgRef.classList.add('slide-in-left');
                                }, index * 300);
                            } else if (index > 4 && index <= 8) {
                                setTimeout(() => {
                                    imgRef.classList.add('slide-in-left');
                                }, index * 300);
                            }
                            else {
                                setTimeout(() => {
                                    imgRef.classList.add('slide-in-right');
                                }, index * 300);
                            }

                        });
                        setLoaded(true);
                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            observer.observe(sectionRef.current);
            return () => {
                observer.disconnect();
            };
        } else {
            setLoaded(true);
        }
    }, []);

    return (
        <div className="container-fluid py-5 choose-us" ref={sectionRef}>
            <div className="container">
                <div className="row g-5 align-items-center ">
                    <div className="col-lg-12 wow fadeIn text-center">
                        <div className="btn btn-sm text-primary px-3 mb-3">Why choose us</div>
                        <h2 className="mb-4 d-none-mob">Beyond Support: Elevate Your <br />Business, Simplifying Your Tasks.</h2>
                        <h2 className="mb-4 d-none">Beyond Support: Elevate Your Business, Simplifying Your Tasks.</h2>
                    </div>
                    <div className="col-lg-6 wow fadeIn hide-image" ref={el => imagesRef.current[1] = el}>
                        <div className="about-img-main">
                            <div className="img-main">
                                <img ref={el => imagesRef.current[2] = el} className={`img-fluid chat-1as hide-image`} src={chat1} alt="chat-1" />
                                <img ref={el => imagesRef.current[3] = el} className={`img-fluid chat-2 hide-image`} src={chat2} alt="chat-2" />
                                <img ref={el => imagesRef.current[4] = el} className="img-fluid chat-3 hide-image" src={chat3} alt="chat-3" />
                                <img ref={el => imagesRef.current[5] = el} className="img-fluid chat-4 hide-image" src={chat4} alt="chat-4" />
                            </div>
                            <div className="img-bottom">
                                <img className="img-fluid ellipse" src={ellipse} alt="ellipse" />
                                <img ref={el => imagesRef.current[7] = el} className="img-fluid chat-5 hide-image" src={chat5} alt="chat-5" />
                                <img ref={el => imagesRef.current[8] = el} className="img-fluid chat-5top hide-image" src={chat5top} alt="chat-5-top" />
                                <img ref={el => imagesRef.current[6] = el} className="img-fluid v-bottom hide-image" src={vector6} alt="vector-6" />
                            </div>
                            <div className="img-side">
                                <img ref={el => imagesRef.current[9] = el} className="img-fluid v-side hide-image" src={vector5} alt="vector-5" />
                                <img ref={el => imagesRef.current[10] = el} className="img-fluid brnd-1 hide-image" src={brandbg} alt="brand-bg" />
                                <img ref={el => imagesRef.current[11] = el} className="img-fluid wix hide-image" src={wix} alt="wix" />
                                <img ref={el => imagesRef.current[12] = el} className="img-fluid brnd-2 hide-image" src={brandbg} alt="brand-bg" />
                                <img ref={el => imagesRef.current[13] = el} className="img-fluid shopify hide-image" src={shopify} alt="shopify" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn hide-image" ref={el => imagesRef.current[0] = el}>
                        <div className="d-flex p-3">
                            <img className="icon-img" src={thumb_up} width="75" height="75" style={{ background: '#13062a' }} />
                            <div className="ms-3">
                                <h5 className="text-white ">Real-time responses</h5>
                                <p>Our advanced AI system instantly resolves 90% of customer inquiries in real time, providing accurate support when needed and freeing your team to focus on core business activities.</p>
                            </div>
                        </div>
                        <div className="d-flex p-3">
                            <img className="icon-img" src={tree_png} width="75" height="75" style={{ background: '#241118' }} />
                            <div className="ms-3">
                                <h5 className="text-white">Seamless Integration</h5>
                                <p>EVA seamlessly integrates with Shopify, WooCommerce, along with key social media, email, and more, ensuring smooth interactions across all customer touchpoints.</p>
                            </div>
                        </div>
                        <div className="d-flex p-3">
                            <img className="icon-img" src={querystats} width="75" height="75" style={{ background: '#24081a' }} />
                            <div className="ms-3">
                                <h5 className="text-white">Deep Conversation Insights</h5>
                                <p>Eva doesn’t just answer inquiries. Uncover the hidden layers of customer conversations with our AI-driven analysis. Instantly assess sentiment, categorize queries, and extract actionable insights.</p>
                            </div>
                        </div>
                        <div className="d-flex p-3">
                            <img className="icon-img" src={chat} width="75" height="75" style={{ background: '#16151f' }} />
                            <div className="ms-3">
                                <h5 className="text-white">Multilingual Support</h5>
                                <p>Expand your reach globally with our support for 25 languages. Break down language barriers and connect with your international customers in their native language, providing a personalized and inclusive customer service experience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section1;