import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authSlice from './reducer/auth';
import { useDispatch, useSelector } from 'react-redux';
const rootReducer = combineReducers({
  auth: authSlice,
});

const store = configureStore({
  reducer: rootReducer,
});

export const useAppSelector = useSelector;
export const useAppDispatch = () => useDispatch();
export default store;
