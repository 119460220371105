import React, { useState, useEffect } from 'react';
import mobilehero from 'assets/img/mobile-hero.png';

const NewHero = () => {

  const [loaded, setLoaded] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const words = ['E','El','Ele','Elev','Eleva','Elevat','Elevate','Elevate','Elevat','Eleva','Elev','Ele','El','E','Em','Emp','Empo','Empow','Empowe','Empower','Empower','Empowe','Empow','Empo','Emp','Em','E', 'En','Enh','Enha','Enhan','Enhanc','Enhance','Enhance','Enhanc','Enhan','Enha','Enh','En','E'];

  useEffect(() => {
    setLoaded(true);

    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className={`container-fluid pt-5 bg-primary hero-header mb-5 ${loaded ? 'loaded' : ''}`}>
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className={`col-lg-6 align-self-center text-center text-lg-start mb-lg-5 ${loaded ? 'slide-in-right' : ''}`}>
              <img className="d-none" src={mobilehero} />
              <h1 className="display-6 text-white mb-4 pt-5 animated slideInRight">
              <span className='changingtextmain' style={{}}><span className={`change typing-animation`}>{words[currentWordIndex]}</span> Your</span> <br />
                Customer Service with <br />
                EVA: Resolve Up to 90% <br />
                of Inquiries Instantly!
              </h1>
              <p className="mb-4 animated slideInRight">Unlock unparalleled efficiency with real-time responses, 24/7 support, comprehensive order management, multilingual capabilities, and actionable insights—all at your fingertips.</p>
              <div className="text-center">
                <a href="#" className="btn btn-light py-sm-3 px-sm-5 me-5 animated slideInRight animated-btn">Get Early Access</a>
              </div>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default NewHero;