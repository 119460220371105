import axios from 'axios';
import queryString from 'query-string';

const config = {
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: params => queryString.stringify(params)
};

const axiosClient = axios.create(config);

axiosClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers['Authorization'] = token;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    throw error;
  }
);
export default axiosClient;
