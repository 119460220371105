import React, { useState, useEffect, useRef } from 'react';
import ellipse from 'assets/img/chat-img/Ellipse.png';
import vector1 from 'assets/img/chat-img/Vector-1.png';
import case1 from 'assets/img/chat-img/case-1.png';
import vector2 from 'assets/img/chat-img/Vector-2.png';
import case2 from 'assets/img/chat-img/case-2.png';
import case3 from 'assets/img/chat-img/case-3.png';
import untitiledesign from "assets/img/untitled-design.png";
import vector11 from "assets/img/Vector-11.png";
import Multichat1 from "assets/img/multi-chat-1.png";
import multichat2 from "assets/img/multi-chat-2.png";
import multichat3 from "assets/img/multi-chat-3.png";
import multichat4 from "assets/img/multi-chat-4.png";
import reso from "assets/img/reso.png";
import vector12 from "assets/img/Vector12.png";



const Multisection = () => {
    const [loaded, setLoaded] = useState(false);

    const sectionRef = useRef(null);
    const imagesRef = useRef([]);


    const isMobileDevice = () => {
        return window.matchMedia('(max-width: 468px)').matches;
    };

    useEffect(() => {
        // const handleScroll = () => {
        //     const sectionTop = sectionRef.current.getBoundingClientRect().top;
        //     if (!isMobileDevice() && sectionTop <= window.innerHeight * 0.00001) {
        //         setShowMultilingualContent(true);
        //         window.removeEventListener('scroll', handleScroll);
        //     }
        // };

        // window.addEventListener('scroll', handleScroll);
        // return () => {
        //     window.removeEventListener('scroll', handleScroll);
        // };
    }, []);

    useEffect(() => {
        if (!isMobileDevice() && 'IntersectionObserver' in window) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            };

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        imagesRef.current.forEach((imgRef, index) => {
                            if (index < 6) {
                                setTimeout(() => {

                                    imgRef.classList.add('slide-in-img-multi');
                                }, index * 50);

                            } else {
                                setTimeout(() => {
                                    // setShowMultilingualContent(true);
                                    imgRef.classList.add('slide-in-img-right');

                                }, index * 2300);
                            }
                        });
                        setLoaded(true);
                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            observer.observe(sectionRef.current);
            return () => {
                observer.disconnect();
            };
        } else {
            setLoaded(true);
        }
    }, []);

    return (<>
        {/* <div class="col-lg-6 wow fadeIn swatch-one fadeIn" style={{ transform: '' }} ref={sectionRef}>

            <div class="multi-main-channel">
                <div class="img-main" ref={el => imagesRef.current[0] = el} >
                    <img class="multi-main-one" src={untitiledesign} ref={el => imagesRef.current[4] = el}/>
                    <img class="multi-main-two" src={vector11} />
                    <div class="multi-chat" ref={el => imagesRef.current[1] = el}>
                        <img class="multi-chat-one" src={Multichat1} />
                        <img class="multi-chat-two" src={multichat2} />
                        <img class="multi-chat-three" src={multichat3} />
                        <img class="multi-chat-four" src={multichat4} />
                    </div>
                    <img class="multi-main-three" src={reso}  ref={el => imagesRef.current[3] = el}/>
                    <img class="multi-main-four" src={vector12} ref={el => imagesRef.current[2] = el} />

                </div>
            </div>
        </div> */}
        <div class="col-lg-6 wow fadeIn swatch-one fadeIn" style={{ transform: '' }} ref={sectionRef}>

            <div class="multi-main-channel">
                <div class="img-main" ref={el => imagesRef.current[0] = el} >
                    <img class="multi-main-one" src={untitiledesign} />
                    <img class="multi-main-two" src={vector11} />
                    <div class="multi-chat">
                        <img class="multi-chat-one" src={Multichat1} />
                        <img class="multi-chat-two" src={multichat2} />
                        <img class="multi-chat-three" src={multichat3} />
                        <img class="multi-chat-four" src={multichat4} />
                    </div>
                    <img class="multi-main-three" src={reso} />
                    <img class="multi-main-four" src={vector12}  />

                </div>
            </div>
        </div>
    </>)


}
export default Multisection;