import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NewLayout from '../layouts/NewLayout';
import Home from 'components/newhome/newhome';




const AppRoutes = () => {
  return (
    <Routes>
  
      <Route element={<NewLayout />}>
        <Route path="/" element={<Home />} />
      </Route>

    </Routes>
  );
};

export default AppRoutes;
