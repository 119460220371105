import React, { useState, useEffect } from 'react';
import logo from 'assets/img/logo.png';

const Navbar = () => {

  const [scrolled, setScrolled] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <div className={`container-fluid ${scrolled ? 'fixed-top bg-dark' : ''}`}>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark p-0">
          <a href="/" className="navbar-brand">
            <img src={logo} alt="logo" width="110px" />
          </a>
          <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav mx-auto">
              <a href="/" className="nav-item nav-link active">Home</a>
              <a href="#" className="nav-item nav-link">Capabilities</a>
              <a href="#" className="nav-item nav-link">Insights</a>
              <a href="#" className="nav-item nav-link">Integration</a>
              <a href="#" className="nav-item nav-link">Support</a>
            </div>
            <a href="" className={`btn btn-light py-sm-3 px-sm-5 me-3 animated slideInRight animated-btn ${loaded ? 'slide-in-right' : ''}`}>Get Early Access</a>
          </div>
        </nav>
      </div>
    </div>
  )

}

export default Navbar;