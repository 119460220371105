import React, { useState, useEffect, useRef } from 'react';
import ellipse from 'assets/img/chat-img/Ellipse.png';
import vector1 from 'assets/img/chat-img/Vector-1.png';
import case1 from 'assets/img/chat-img/case-1.png';
import vector2 from 'assets/img/chat-img/Vector-2.png';
import case2 from 'assets/img/chat-img/case-2.png';
import case3 from 'assets/img/chat-img/case-3.png';




const Multisection2 = () => {
    const [loaded, setLoaded] = useState(false);

    const sectionRef = useRef(null);
    const imagesRef = useRef([]);


    const isMobileDevice = () => {
        return window.matchMedia('(max-width: 468px)').matches;
    };

    useEffect(() => {
        // const handleScroll = () => {
        //     const sectionTop = sectionRef.current.getBoundingClientRect().top;
        //     if (!isMobileDevice() && sectionTop <= window.innerHeight * 0.00001) {
        //         setShowMultilingualContent(true);
        //         window.removeEventListener('scroll', handleScroll);
        //     }
        // };

        // window.addEventListener('scroll', handleScroll);
        // return () => {
        //     window.removeEventListener('scroll', handleScroll);
        // };
    }, []);

    useEffect(() => {
        if (!isMobileDevice() && 'IntersectionObserver' in window) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            };

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        imagesRef.current.forEach((imgRef, index) => {
                            if (index < 6) {
                                setTimeout(() => {
                                   
                                    imgRef.classList.add('slide-in-img-multi');
                                }, index * 100);

                            } else {
                                // setTimeout(() => {
                                //     // setShowMultilingualContent(true);  

                                //     imgRef.classList.add('slide-in-img-right');

                                // }, index * 300);
                            }
                        });
                        setLoaded(true);
                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            observer.observe(sectionRef.current);
            return () => {
                observer.disconnect();
            };
        } else {
            setLoaded(true);
        }
    }, []);

    return (<>
        {/* <div className="col-lg-6 wow fadeIn swatch-one" ref={sectionRef}>
            <div className="case-img-main" >
                <div className="img-main">
                    <img className={`img-fluid case-1  `} ref={el => imagesRef.current[5] = el} src={case1} alt="Case 1" />
                    <img className={`img-fluid case-4 `} src={vector2} alt="Case 4" ref={el => imagesRef.current[2] = el} />
                    <img className={`img-fluid case-2 `} ref={el => imagesRef.current[4] = el} src={vector1} alt="Case 2" />
                    <img className={`img-fluid case-3 `} ref={el => imagesRef.current[0] = el} src={case2} alt="Case 3" />
                    <img className={`img-fluid ellipse `} src={ellipse} alt="Ellipse" ref={el => imagesRef.current[1] = el} />
                    <img className={`img-fluid case-5 `} src={case3} alt="Case 5" ref={el => imagesRef.current[3] = el} />
                </div>
            </div>
        </div> */}
        <div className="col-lg-6 wow fadeIn swatch-one" ref={sectionRef}>
            <div className="case-img-main" >
                <div className="img-main" ref={el => imagesRef.current[0] = el}>
                    <img className={`img-fluid case-1  `}  src={case1} alt="Case 1" />
                    <img className={`img-fluid case-4 `} src={vector2} alt="Case 4"  />
                    <img className={`img-fluid case-2 `}  src={vector1} alt="Case 2" />
                    <img className={`img-fluid case-3 `}  src={case2} alt="Case 3" />
                    <img className={`img-fluid ellipse `} src={ellipse} alt="Ellipse"  />
                    <img className={`img-fluid case-5 `} src={case3} alt="Case 5"  />
                </div>
            </div>
        </div>
    </>)


}
export default Multisection2;