import React, { useState, useEffect, useRef } from 'react';

import ellipse from 'assets/img/chat-img/Ellipse.png';
import paidicon from 'assets/img/icon/paid_icon.png';
import requestquote from "assets/img/icon/request_quote.png";
import gppbad from "assets/img/icon/gpp_bad.png";
import vector4 from "assets/img/chat-img/Vector-4-1.png";
import vector42 from "assets/img/chat-img/Vector-4-2.png";
import chat6 from 'assets/img/chat-img/chat-6.png';
import chat7 from 'assets/img/chat-img/chat-7.png';
import chat8 from 'assets/img/chat-img/chat-8.png';

const Section2 = () => {
    const [loaded, setLoaded] = useState(false);
    const sectionRef = useRef(null);
    const imagesRef = useRef([]);

    useEffect(() => {

        if ('IntersectionObserver' in window) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            };

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {

                        imagesRef.current.forEach((imgRef, index) => {
                            if (index == 0) {
                                setTimeout(() => {
                                    imgRef.classList.add('slide-in-img-right');
                                }, index * 200);
                            } else if (index > 0 && index <= 4) {
                                setTimeout(() => {
                                    imgRef.classList.add('slide-in-left');
                                }, index * 200);
                            }
                            else {
                                setTimeout(() => {
                                    imgRef.classList.add('slide-in-right');
                                }, index * 200);
                            }

                        });
                        setLoaded(true);
                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            observer.observe(sectionRef.current);
            return () => {
                observer.disconnect();
            };
        } else {
            setLoaded(true);
        }
    }, []);

    return (
        <div className="container-fluid py-5 choose-us-two" ref={sectionRef}>
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeIn view-one">
                        <div className="btn btn-sm text-primary mb-3">Why choose us</div>
                        <h2 className="mb-4">Order Management Made Easy</h2>
                        <p>
                            EVA revolutionizes order management by effortlessly handling
                            cancellations, modifications, refunds, and inquiries. Fully
                            integrated with Shopify, WooCommerce, and Wix, EVA tailors its
                            operations to your specific business policies, ensuring accurate
                            and timely responses that align with your company's standards.
                        </p>
                        <div className="d-flex p-3">
                            <img
                                className="icon-img"
                                src={paidicon}
                                width="75"
                                height="75"
                                style={{ background: '#13062a' }}
                            />
                            <div className="ms-3">
                                <h5 className="text-white">Approve and Disapprove Refunds</h5>
                                <p>
                                    Define your refund policy within Eva and automatically inform
                                    customers about their eligibility. Our intuitive interface
                                    simplifies the management of refund requests, enabling
                                    decisions that perfectly align with your business policies
                                </p>
                            </div>
                        </div>
                        <div className="d-flex p-3">
                            <img
                                className="icon-img"
                                src={requestquote}
                                width="75"
                                height="75"
                                style={{ background: '#241118' }}
                            />
                            <div className="ms-3">
                                <h5 className="text-white">Order Modification Requests</h5>
                                <p>
                                    Eva adapts to your order management policies, allowing for
                                    seamless order modifications. Customers can submit requests
                                    directly through Eva, and you can approve or disapprove these
                                    modifications within our user-friendly interface.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex p-3">
                            <img
                                className="icon-img"
                                src={gppbad}
                                width="75"
                                height="75"
                                style={{ background: '#24081a' }}
                            />
                            <div className="ms-3">
                                <h5 className="text-white">Effortless Order Cancellation</h5>
                                <p>
                                    Eva respects your business policies, streamlining the
                                    cancellation process. Simplify how you manage cancellations,
                                    with Eva automating the procedure according to your guidelines.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn view-two hide-image" ref={el => imagesRef.current[0] = el}>
                        <div className="choose-us-main">
                            <div className="main-img" >
                                <img className="img-fluid chat-6 hide-image" src={chat6} ref={el => imagesRef.current[1] = el} />
                                <img className="img-fluid chat-8 hide-image" src={chat8} ref={el => imagesRef.current[3] = el} />
                                <img className="img-fluid chat-7 hide-image" src={chat7} ref={el => imagesRef.current[6] = el} />
                            </div>
                            <div className="bottom-img">
                                <img className="img-fluid ellipse hide-image" src={ellipse} ref={el => imagesRef.current[2] = el} />
                                <img className="img-fluid v-bottom hide-image" src={vector4} ref={el => imagesRef.current[4] = el} />
                                <img className="img-fluid v-bottom two hide-image" src={vector42} ref={el => imagesRef.current[5] = el} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section2;