import React, { useState, useEffect, useRef } from 'react';
import quoteimage from "assets/img/testimonials/quote.png";
import substract from "assets/img/testimonials/Subtract.png";
import testimonialuser5 from "assets/img/testimonials/testimonial-user-5.png";

const Testimonials1 = ({ testimonialnumber }) => {
    const [loaded, setLoaded] = useState(false);
    const sectionRef = useRef(null);
    const imagesRef = useRef([]);



    useEffect(() => {
        const isMobileDevice = () => {
            return window.matchMedia('(max-width: 468px)').matches;
        };



        if (!isMobileDevice() && 'IntersectionObserver' in window) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            };

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        imagesRef.current.forEach((imgRef, index) => {
                            if (index < 6) {
                                setTimeout(() => {

                                    imgRef.classList.add('slide-in-img-multi');
                                }, index * 50);

                            } else {
                                setTimeout(() => {
                                    // setShowMultilingualContent(true);
                                    imgRef.classList.add('slide-in-img-right');

                                }, index * 2300);
                            }
                        });
                        setLoaded(true);
                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            observer.observe(sectionRef.current);
            return () => {
                observer.disconnect();
            };
        } else {
            setLoaded(true);
        }
    }, []);

    return (
        <>

            <div ref={sectionRef}>
                <div className="testemonials-box-four" ref={el => imagesRef.current[0] = el} >


                    <img className="img-fluid testemonials-4" src={testimonialuser5} />
                    <img className="img-fluid testemonials-5" src={quoteimage} />
                    <img className="img-fluid testemonials-6" src={substract} />
                    <div className="testemonials-content">
                        <h6>Rocky Dixon</h6>
                        <p>The AI chatbot was efficient and user-friendly, providing prompt and relevant responses to my inquiries. Interactions felt natural and seamless, making it a valuable tool for assistance.</p>
                    </div>

                </div>
            </div>


        </>

    );
};

export default Testimonials1;
