import React, { useState, useEffect, useRef } from 'react';

import Multisection from './multisection';
import Multisection2 from './multisection2';



const Section3 = () => {
    const [loaded, setLoaded] = useState(false);
    const [showMultilingualContent, setShowMultilingualContent] = useState(false);
    const sectionRef = useRef(null);
    const imagesRef = useRef([]);

    const handleMultilingualSupportClick = (showid) => {
        setShowMultilingualContent(showid === 1);
    };

    const isMobileDevice = () => {
        return window.matchMedia('(max-width: 468px)').matches;
    };

    useEffect(() => {
        const handleScroll = () => {
            const sectionTop = sectionRef.current.getBoundingClientRect().top;
            if (!isMobileDevice() && sectionTop <= window.innerHeight * 0.00001) {
                setShowMultilingualContent(true);
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (!isMobileDevice() && 'IntersectionObserver' in window) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            };

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        imagesRef.current.forEach((imgRef, index) => {
                            if (index < 6) {
                                setTimeout(() => {

                                    imgRef.classList.add('slide-in-img-right');
                                }, index * 300);

                            } else {
                                setTimeout(() => {
                                    // setShowMultilingualContent(true);
                                    imgRef.classList.add('slide-in-img-right');

                                }, index * 2300);
                            }
                        });
                        setLoaded(true);
                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            observer.observe(sectionRef.current);
            return () => {
                observer.disconnect();
            };
        } else {
            setLoaded(true);
        }
    }, []);

    return (
        <div className="container-fluid py-5 case-box" ref={sectionRef}>
            <div className="container">
                <div className="row g-5 ">

                    {showMultilingualContent ? (

                        <Multisection />

                    ) : (
                        <Multisection2 />

                    )}




                    <div className="col-lg-6 wow fadeIn swatch-two">

                        <div className="col-lg-12 wow fadeIn mb-5">
                            <div className="btn btn-sm text-primary mb-3">USE CASES</div>
                            <h2 className="mb-4">Powered Customer Support Scenarios: Enhancing E-commerce Experiences</h2>
                        </div>
                        <div className="scroll-box">
                            <div className="btn-container mb-5">
                                <a className="left-btn mr-4" onClick={() => handleMultilingualSupportClick(0)} style={showMultilingualContent ? { background: 'transparent', color: '#D9D9D9', transition: 'opacity 0.3s ease-in-out' } : { background: '#D9D9D9', marginRight: '35px', cursor: 'grab', transition: 'opacity 0.3s ease-in-out' }}>Deep Insights</a>
                                <a className="right-btn" onClick={() => handleMultilingualSupportClick(1)} style={showMultilingualContent ? { background: '#D9D9D9', marginLeft: '35px', transition: 'opacity 0.3s ease-in-out' } : { background: 'transparent', color: '#D9D9D9', cursor: 'grab', transition: 'opacity 0.3s ease-in-out' }} >Multilingual Support</a>
                            </div>
                            {showMultilingualContent ? (
                                <div className='divmulti'  >
                                    <h2 className="mb-4 ">Multi-Language Support: Speak Your Customer's Language</h2>
                                    <p className="">With EVA's intelligent language detection and 25 supported languages, communicating across borders becomes seamless. Automatically responding in your customer's language, EVA eliminates barriers, making every interaction personal and culturally relevant. Tap into new markets with confidence today!</p>
                                </div>
                            ) : (
                                <div className='divmulti'>
                                    <h2 className="mb-4">Advanced Analytics: Uncover Actionable Insights</h2>
                                    <p>Unlock Deeper Understanding for Smarter Engagement: EVA goes beyond solving inquiries, offering an insightful analysis of sentiments, conversation categories, and key topics. With our streamlined dashboard, you gain a comprehensive view of customer interactions, enabling proactive decision-making and enhanced engagement strategies. EVA turns insights into action, setting new standards for customer connection.</p>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section3;
