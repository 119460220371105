import React, { useState, useEffect, useRef } from 'react';

import user1 from 'assets/img/channel/user-1.png';
import uChat1 from 'assets/img/channel/u-chat-1.png';
import ellipse1 from 'assets/img/channel/Ellipse-1.png';
import user2 from 'assets/img/channel/user-2.png';
import uChat2 from 'assets/img/channel/u-chat-2.png';
import user3 from 'assets/img/channel/user-3.png';
import uChat3 from 'assets/img/channel/u-chat-3.png';
import ellipse2 from 'assets/img/channel/Ellipse-2.png';
import aiChannel from 'assets/img/channel/ai.png';
import mailicon from 'assets/img/icon/mail.png';
import descriptionicon from 'assets/img/icon/description.png';
import iconforum from 'assets/img/icon/forum.png';

const Section4 = () => {


    return (
        <div className="container-fluid py-5 channel-box" style={{ backgroundColor: '#0f2e76' }}>
            <div className="container">
                <div className="row g-5 align-items-center py-5">
                    <div className="col-lg-6 wow fadeIn">
                        <div className="channel-img-main">
                            <div className="img-main" >
                                <img className="img-fluid channel-1 floating-image-rings" src={ellipse1} alt="Channel 1" />

                                <div className="sub-img-1 floating-image">
                                    <img className="img-fluid " src={user1} alt="User 1" />
                                    <img className="img-fluid " src={uChat1} alt="User Chat 1" />
                                </div>

                                <img className="img-fluid channel-2 floating-image-rings" src={ellipse2} alt="Channel 2" />

                                <div className="sub-img-2 floating-image1">
                                    <img className="img-fluid " src={user2} alt="User 2" />
                                    <img className="img-fluid " src={uChat2} alt="User Chat 2" />
                                </div>

                                <img className="img-fluid channel-3 floating-image-rings" src={ellipse2} alt="Channel 3" />

                                <div className="sub-img-3 floating-image2">
                                    <img className="img-fluid " src={user3} alt="User 3" />
                                    <img className="img-fluid " src={uChat3} alt="User Chat 3" />
                                </div>

                                <img className="img-fluid channel-4 " src={aiChannel} alt="AI Channel" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 wow fadeIn">
                        <h2 className="mb-4">Multi Channel support.</h2>
                        <p>
                            EVA revolutionizes customer service by operating across multiple communication channels—text, email,
                            and beyond—with the efficiency and dedication of a full customer service team. By aggregating interactions
                            into a unified dashboard, EVA ensures no query goes unanswered
                        </p>
                        <div className="d-flex p-3">
                            <div className="icon-box d-flex align-items-center">
                                <img className="icon-img" src={mailicon} width="55" height="55" style={{ background: '#3d358c91' }} alt="Email Icon" />
                                <h6 className="text-white">Email</h6>
                            </div>
                            <div className="icon-box d-flex align-items-center">
                                <img className="icon-img" src={descriptionicon} width="55" height="55" style={{ background: '#195197' }} alt="Text Icon" />
                                <h6 className="text-white">Text</h6>
                            </div>
                            <div className="icon-box d-flex align-items-center">
                                <img className="icon-img" src={iconforum} width="55" height="55" style={{ background: '#203596' }} alt="Chat bot Icon" />
                                <h6 className="text-white">Chat bot</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section4;