import React, { useState, useEffect, useRef } from 'react';

import testimonialuser7 from "assets/img/testimonials/testimonial-user-7.png";
import testimonialuser4 from "assets/img/testimonials/testimonial-user-4.png";
import testimonialuser6 from "assets/img/testimonials/testimonial-user-6.png";
import testimonialuser5 from "assets/img/testimonials/testimonial-user-5.png";
import testimonialuser1 from "assets/img/testimonials/testimonial-user-1.png";
import testimonialuser2 from "assets/img/testimonials/testimonial-user-2.png";
import testimonialuser3 from "assets/img/testimonials/testimonial-user-3.png";
import quoteimage from "assets/img/testimonials/quote.png";
import substract from "assets/img/testimonials/Subtract.png";
import Slider from "react-slick";
import Testimonials from './testimaonials';
import Testimonials1 from './testimaonials1';
import Testimonials2 from './testimaonials2';

const Section6 = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const sectionRef = useRef(null);
    const [visibleTestimonial, setVisibleTestimonial] = useState(3);




    useEffect(() => {
        const handleScroll = () => {
            const scrollY = sectionRef.current.getBoundingClientRect().top;
            if (scrollY < window.innerHeight * 0.01) {
                setVisibleTestimonial(3);

            } else if (scrollY < window.innerHeight * 0.5) {
                setVisibleTestimonial(4);
            }
            else if (scrollY < window.innerHeight * 1.9) {
                setVisibleTestimonial(1);
            }
            else {
                setVisibleTestimonial(2);
            }
            //else if (scrollY <= window.innerHeight * 0.08) {
            //     // setVisibleTestimonial(3);
            // } else if (scrollY <= window.innerHeight * 0.8) {
            //     // setVisibleTestimonial(4);
            // }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="container-fluid py-5" style={{ backgroundImage: 'linear-gradient(#0f327d -10%, #0d0014 85%)' }} ref={sectionRef}>
            <div className="container">
                <div className="row g-5 align-items-center py-5">
                    <div className="col-lg-12 wow fadeIn text-center">
                        <div className="btn btn-sm text-primary px-3 mb-3">Testimonials</div>
                        <h2 className="mb-4">Our clients express their <br />appreciation for our services</h2>
                    </div>

                    <div className="col-lg-12 wow fadeIn testemonials mt-5 desktop">
                        <div className="testemonials-img-main">
                            <div className="img-main text-center">
                                {visibleTestimonial == 1 ? (<>
                                    {/* <div className="testemonials-box-one" >
                                        <img className="img-fluid testemonials-1" src={testimonialuser7} />
                                        <img className="img-fluid testemonials-5" src={quoteimage} />
                                        <img className="img-fluid testemonials-6" src={substract} />
                                        <div className="testemonials-content">
                                            <h6>Maria</h6>
                                            <p>The AI chatbot was efficient and user-friendly, providing prompt and relevant responses to my inquiries. Interactions felt natural and seamless, making it a valuable tool for assistance.</p>
                                        </div>
                                    </div> */}
                                    <Testimonials  />

                                </>) : (<>
                                    <img className="img-fluid testemonials-1" src={testimonialuser7} />
                                </>)}

                                {visibleTestimonial == 2 ? (<>

                                    <Testimonials1  />
                                </>) : (<>
                                    <img className="img-fluid testemonials-2" src={testimonialuser4} />
                                </>)}

                                {visibleTestimonial == 3 ? (
                                    <>
                                     <Testimonials2 />
                                    </>
                                ) : (<>
                                    <img className="img-fluid testemonials-3" src={testimonialuser6} />
                                </>)}

                                {visibleTestimonial == 4 ? (
                                    // <div className="testemonials-box-four" >
                                    //     <img className="img-fluid testemonials-4" src={testimonialuser5} />
                                    //     <img className="img-fluid testemonials-5" src={quoteimage} />
                                    //     <img className="img-fluid testemonials-6" src={substract} />
                                    //     <div className="testemonials-content">
                                    //         <h6>Rocky Dixon</h6>
                                    //         <p>The AI chatbot was efficient and user-friendly, providing prompt and relevant responses to my inquiries. Interactions felt natural and seamless, making it a valuable tool for assistance.</p>
                                    //     </div>
                                    // </div>
                                    <Testimonials1  />
                                ) : (<>
                                    <img className="img-fluid testemonials-4" src={testimonialuser5} />
                                </>)}



                                <img className="img-fluid testemonials-7" src={testimonialuser1} />
                                <img className="img-fluid testemonials-8" src={testimonialuser3} />
                                <img className="img-fluid testemonials-9" src={testimonialuser2} />
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-12 wow fadeIn testemonials mt-5 mobile">
                        <div className="carousel slide d-none slider-container" >
                            <Slider {...settings}>
                                <div className="testemonials-img-main">
                                    <div className="img-main text-center">
                                        <div className="testemonials-box-one" >
                                            <img className="img-fluid testemonials-1" src={testimonialuser7} />
                                            <img className="img-fluid testemonials-5" src={quoteimage} />
                                            <img className="img-fluid testemonials-6" src={substract} />
                                            <div className="testemonials-content">
                                                <h6>Maria</h6>
                                                <p>The AI chatbot was efficient and user-friendly, providing prompt and relevant responses to my inquiries. Interactions felt natural and seamless, making it a valuable tool for assistance.</p>
                                            </div>
                                        </div>          </div>
                                </div>
                                <div className="testemonials-img-main">
                                    <div className="img-main text-center">
                                        <div className="testemonials-box-one" >
                                            <img className="img-fluid testemonials-1" src={testimonialuser4} />
                                            <img className="img-fluid testemonials-5" src={quoteimage} />
                                            <img className="img-fluid testemonials-6" src={substract} />
                                            <div className="testemonials-content">
                                                <h6>Andrew Bill</h6>
                                                <p>The AI chatbot was efficient and user-friendly, providing prompt and relevant responses to my inquiries. Interactions felt natural and seamless, making it a valuable tool for assistance.</p>
                                            </div>
                                        </div>          </div>
                                </div>
                                <div className="testemonials-img-main">
                                    <div className="img-main text-center">
                                        <div className="testemonials-box-one" >
                                            <img className="img-fluid testemonials-1" src={testimonialuser5} />
                                            <img className="img-fluid testemonials-5" src={quoteimage} />
                                            <img className="img-fluid testemonials-6" src={substract} />
                                            <div className="testemonials-content">
                                                <h6>Rocky Dixon</h6>
                                                <p>The AI chatbot was efficient and user-friendly, providing prompt and relevant responses to my inquiries. Interactions felt natural and seamless, making it a valuable tool for assistance.</p>
                                            </div>
                                        </div>          </div>
                                </div>
                                <div className="testemonials-img-main">
                                    <div className="img-main text-center">
                                        <div className="testemonials-box-one" >
                                            <img className="img-fluid testemonials-1" src={testimonialuser6} />
                                            <img className="img-fluid testemonials-5" src={quoteimage} />
                                            <img className="img-fluid testemonials-6" src={substract} />
                                            <div className="testemonials-content">
                                                <h6>John Stuart</h6>
                                                <p>The AI chatbot was efficient and user-friendly, providing prompt and relevant responses to my inquiries. Interactions felt natural and seamless, making it a valuable tool for assistance.</p>
                                            </div>
                                        </div>          </div>
                                </div>


                            </Slider>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section6;