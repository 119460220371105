import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from 'config/axios';

const initialState = {
  user: null,
  token: localStorage.getItem('token'),
  error: '',
  isLoading: false,
};


export const setToken = createAsyncThunk('token', (data) => {
  return data;
});

export const register = createAsyncThunk(
  'auth/register',
  async (valueForm, thunkAPI) => {
    try {
      const res = await axiosClient.post('/register', valueForm);
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (formValue, thunkAPI) => {
    try {
      const res = await axiosClient.post('/login', formValue);
      localStorage.setItem('token', res?.data.accessToken);
      return res.data;
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  }
);

export const getUser = createAsyncThunk(
  'auth/user',
  async (thunkAPI) => {
    try {
      const res = await axiosClient.get('/auth/user');
      return res.data;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
      if (error.response.status === 401) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  }
);

export const logout = createAsyncThunk('auth/logout', () => {
  localStorage.setItem('token', '');
});



const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [setToken.fulfilled.toString()]: (state, action) => {
      state.token = action.payload;
    },


    [register.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [register.fulfilled.toString()]: (state) => {
      state.error = '';
      state.isLoading = false;
      state.showMessage = true;
    },
    [register.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },


    [login.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [login.fulfilled.toString()]: (state, action) => {
      state.error = '';
      state.token = action.payload.accessToken;
      state.user = action.payload.user;
      state.isLoading = false;
    },
    [login.rejected.toString()]: (state, action) => {
      state.user = null;
      state.token = '';
      state.error = action.payload;
      state.isLoading = false;
    },

    
    [logout.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [logout.fulfilled.toString()]: (state) => {
      state.token = '';
      state.isLoading = false;
    },
    [logout.rejected.toString()]: (state) => {
      state.isLoading = false;
    },


    [getUser.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getUser.fulfilled.toString()]: (state, action) => {
      state.error = '';
      state.user = action.payload.user;
      state.isLoading = false;
    },
    [getUser.rejected.toString()]: (state, action) => {
      state.user = null;
      state.token = '';
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});


export const selectToken = (state) => state.auth.token;
export const selectUser = (state) => state.auth.user;
export const selectIsLoading = (state) => state.auth.isLoading;
export const selectError = (state) => state.auth.error;

export default authSlice.reducer;
