import React from 'react';

const Newsletter = () => {
    return (
        <div className="container-fluid newsletter py-5">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-md-12 py-5 newsletter-text align-items-center text-center wow fadeIn">
                        <div className="btn btn-sm text-primary px-3 mb-3">Support</div>
                        <h2 className="mb-4">Subscribe Newsletter & get</h2>
                        <h3 className="mb-4" style={{ fontWeight: 'normal !important' }}>Company News</h3>
                        <div className="position-relative newsletter-form w-100 mt-3 mb-2">
                            <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text"
                                placeholder="Enter Your Email" style={{ height: '48px' }} />
                            <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i
                                className="fa fa-paper-plane text-primary fs-4"></i> Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newsletter;