import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NewHeader from 'components/newheader/header';
import NewFooter from 'components/newfooter/footer';
import 'font-awesome/css/font-awesome.min.css';

const NewLayout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (

    <>
      <NewHeader />
      <Outlet />
      <NewFooter />
    </>
  );
};

export default NewLayout;
