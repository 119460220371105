import React from 'react';


const NewFooter = () => {
    return (
        <div className="container-fluid text-white-50 footer">
            <div className="container wow fadeIn">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-12 text-center">Copyright &copy; <a className="border-bottom" href="#">2024 Simple Software Services.</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default NewFooter;