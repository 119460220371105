import React from 'react';


import shopifyicon from 'assets/img/icon/shopify.png';
import wooicon from "assets/img/icon/woo.png";
import wixicon from "assets/img/icon/wix.png";
import logoicon from "assets/img/icon/logo.png";
import spotifyicon from "assets/img/icon/spotify.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Section5 = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]
    };
    return (
        <div className="container-fluid py-5 interation" style={{ backgroundColor: '#0e1c4f' }}>
            <div className="container">
                <div className="row g-5 align-items-center py-5">
                    <div className="col-lg-12 wow fadeIn text-center">
                        <div className="btn btn-sm text-primary px-3 mb-3">INTEGRATION</div>
                        <h2 className="mb-4 d-none-mob">Integrate with your existing tools <br />in a few clicks</h2>
                        <h2 className="mb-4 d-none">Integrate with your existing tools in a few clicks</h2>
                    </div>
                    <div className="col-lg-12 wow fadeIn">
                        <div className="interation-img-main">
                            <div className="img-main text-center d-none-mob">
                                <img className="img-fluid interation-1" src={shopifyicon} />
                                <img className="img-fluid interation-2" src={wooicon} />
                                <img className="img-fluid interation-3" src={wixicon} />
                                <img className="img-fluid interation-4" src={logoicon} />
                                <img className="img-fluid interation-5" src={spotifyicon} />
                            </div>
                            <div  className="carousel slide d-none slider-container" >
                                <Slider {...settings}>
                                    <div>
                                    <img className="img-fluid interation-1" src={shopifyicon} />
                                    </div>
                                    <div>
                                    <img className="img-fluid interation-2" src={wooicon} />
                                    </div>
                                    <div>
                                    <img className="img-fluid interation-3" src={wixicon} />
                                    </div>
                                    <div>
                                    <img className="img-fluid interation-4" src={logoicon} />
                                    </div>
                                    <div>
                                    <img className="img-fluid interation-5" src={spotifyicon} />
                                    </div>
                                   
                                </Slider>
                            </div>

                            {/* <div id="carouselExampleIndicators" className="carousel slide d-none" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img className="img-fluid interation-1 d-block w-100" src={shopifyicon} alt="Slide 1" />
                                        <img className="img-fluid interation-2 d-block w-100" src={wooicon} alt="Slide 1" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid interation-3 d-block w-100" src={wixicon} alt="Slide 2" />
                                        <img className="img-fluid interation-4 d-block w-100" src={logoicon} alt="Slide 2" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid interation-5 d-block w-100" src={spotifyicon} alt="Slide 3" />
                                        <img className="img-fluid interation-1 d-block w-100" src={shopifyicon} alt="Slide 3" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div> */}
                        </div>
                    </div>
                    <div className="text-center">
                        <a href="" className="btn btn-light py-sm-3 px-sm-5 me-5 animated slideInRight animated-btn">Get Early Access</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section5;