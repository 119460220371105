import React from 'react';
import Navbars from './navbar';
import NewHero from './newhero';


const NewHeader = () => {
  return (
    <>
      {/* <Spinner /> */}
      <Navbars />
      <NewHero />

    </>
  )

}
export default NewHeader;