import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
    return (
        <div className="container-fluid py-5">
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="btn btn-sm text-primary px-3 mb-3">FAQ</div>
                        <h2 className="mb-4">Answers at Your Fingertips: <br />FAQ Hub</h2>
                        <p>Your go-to resource for quick answers to commonly asked questions about our products, services, and policies.</p>
                    </div>
                    <div className="col-lg-6">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{
                                    bgcolor: "#0E0722",
                                    color: "white",
                                    boxShadow: 1,
                                    borderColor: "black",
                                    p: 2,
                                    mb: 2,
                                }}
                            >
                                What kind of customer queries can the AI chatbot handle?
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                bgcolor: "#0f0216",
                                color: "white",
                                boxShadow: 1,
                                borderColor: "black",
                                p: 2,
                            }}>
                                Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam sed sed magna et magna diam aliquyam amet dolore ipsum erat duo. Sit rebum magna duo labore no diam.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{
                                    bgcolor: "#0E0722",
                                    color: "white",
                                    boxShadow: 1,
                                    borderColor: "black",
                                    p: 2,
                                    mb: 2,
                                }}
                            >
                                What kind of customer queries can the AI chatbot handle?
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                bgcolor: "#0f0216",
                                color: "white",
                                boxShadow: 1,
                                borderColor: "black",
                                p: 2,
                            }}>
                                Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam sed sed magna et magna diam aliquyam amet dolore ipsum erat duo. Sit rebum magna duo labore no diam.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{
                                    bgcolor: "#0E0722",
                                    color: "white",
                                    boxShadow: 1,
                                    borderColor: "black",
                                    p: 2,
                                    mb: 2,
                                }}
                            >
                                What kind of customer queries can the AI chatbot handle?
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                bgcolor: "#0f0216",
                                color: "white",
                                boxShadow: 1,
                                borderColor: "black",
                                p: 2,
                            }}>
                                Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam sed sed magna et magna diam aliquyam amet dolore ipsum erat duo. Sit rebum magna duo labore no diam.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{
                                    bgcolor: "#0E0722",
                                    color: "white",
                                    boxShadow: 1,
                                    borderColor: "black",
                                    p: 2,
                                    mb: 2,
                                }}
                            >
                                What kind of customer queries can the AI chatbot handle?
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                bgcolor: "#0f0216",
                                color: "white",
                                boxShadow: 1,
                                borderColor: "black",
                                p: 2,
                            }}>
                                Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam sed sed magna et magna diam aliquyam amet dolore ipsum erat duo. Sit rebum magna duo labore no diam.
                            </AccordionDetails>
                        </Accordion>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ;